import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Icon,
  Text,
  HStack,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import { FaCalculator, FaMapMarkerAlt, FaGasPump, FaBook } from 'react-icons/fa';

const ToolsMenu = () => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        variant="ghost"
        rightIcon={<ChevronDownIcon />}
        _hover={{ bg: 'whiteAlpha.200' }}
        _active={{ bg: 'whiteAlpha.300' }}
      >
        Tools
      </MenuButton>
      <MenuList bg="white" borderColor="gray.200">
        <MenuItem
          as={RouterLink}
          to="/tools/freight-class-calculator"
          _hover={{ bg: 'gray.100' }}
        >
          <HStack spacing={3}>
            <Icon as={FaCalculator} color="accent.500" />
            <Text color="gray.700">Freight Class Calculator</Text>
          </HStack>
        </MenuItem>
        <MenuItem
          as={RouterLink}
          to="/tools/zone-lookup"
          _hover={{ bg: 'gray.100' }}
        >
          <HStack spacing={3}>
            <Icon as={FaMapMarkerAlt} color="accent.500" />
            <Text color="gray.700">Zone Lookup</Text>
          </HStack>
        </MenuItem>
        <MenuItem
          as={RouterLink}
          to="/tools/fuel-prices"
          _hover={{ bg: 'gray.100' }}
        >
          <HStack spacing={3}>
            <Icon as={FaGasPump} color="accent.500" />
            <Text color="gray.700">Fuel Prices</Text>
          </HStack>
        </MenuItem>
        <MenuItem
          as={RouterLink}
          to="/tools/glossary"
          _hover={{ bg: 'gray.100' }}
        >
          <HStack spacing={3}>
            <Icon as={FaBook} color="accent.500" />
            <Text color="gray.700">Glossary</Text>
          </HStack>
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default ToolsMenu;
