import api from './axios';

interface RegisterData {
  email: string;
  password: string;
  full_name: string;
  company?: string | null;
}

interface LoginData {
  email: string;
  password: string;
  turnstileToken: string;
}

interface AuthResponse {
  access_token: string;
  refresh_token: string;
  token_type: string;
}

interface PasswordResetRequest {
  email: string;
  turnstileToken: string;
}

interface SetNewPasswordRequest {
  token: string;
  new_password: string;
}

export const register = async (data: RegisterData) => {
  try {
    const response = await api.post('/api/auth/register', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const login = async (data: LoginData): Promise<AuthResponse> => {
  try {
    const formData = new URLSearchParams();
    formData.append('username', data.email);
    formData.append('password', data.password);
    formData.append('turnstile_token', data.turnstileToken);
    formData.append('grant_type', 'password');

    console.log('Sending login request with formData:', Object.fromEntries(formData));

    const response = await api.post<AuthResponse>('/api/auth/token', formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    console.log('Received login response:', response.data);

    if (response.data.access_token) {
      localStorage.setItem('token', response.data.access_token);
      console.log('Stored access token in localStorage');
      
      if (response.data.refresh_token) {
        localStorage.setItem('refresh_token', response.data.refresh_token);
        console.log('Stored refresh token in localStorage');
      } else {
        console.warn('No refresh token in response');
      }
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resendVerification = async (email: string): Promise<void> => {
  try {
    await api.post('/api/auth/resend-verification', { email });
  } catch (error) {
    throw error;
  }
};

export const logout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('refresh_token');
  // The session cookie will be cleared by the browser when we navigate away
  window.location.href = '/login';
};

export const refreshToken = async (): Promise<string> => {
  try {
    const refresh_token = localStorage.getItem('refresh_token');
    if (!refresh_token) {
      throw new Error('No refresh token found');
    }

    const response = await api.post('/api/auth/refresh', { token: refresh_token });

    if (response.data.access_token) {
      localStorage.setItem('token', response.data.access_token);
      if (response.data.refresh_token) {
        localStorage.setItem('refresh_token', response.data.refresh_token);
      }
      return response.data.access_token;
    }

    throw new Error('Failed to refresh token');
  } catch (error) {
    console.error('[AuthService] Token refresh error:', error);
    logout(); // Force logout on refresh failure
    throw error;
  }
};

export const requestPasswordReset = async (data: PasswordResetRequest) => {
  try {
    const response = await api.post('/api/auth/forgot-password', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (data: SetNewPasswordRequest) => {
  try {
    const response = await api.post('/api/auth/reset-password', data);
    return response.data;
  } catch (error) {
    throw error;
  }
};
