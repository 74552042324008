import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    gtag: (...args: any[]) => void;
    dataLayer: any[];
  }
}

const MEASUREMENT_ID = import.meta.env.VITE_GA_MEASUREMENT_ID;

export const GoogleAnalytics = () => {
  const location = useLocation();

  useEffect(() => {
    if (!MEASUREMENT_ID) {
      console.warn('Google Analytics Measurement ID is not set');
      return;
    }

    // Initialize Google Analytics
    const script = document.createElement('script');
    script.async = true;
    script.src = `https://www.googletagmanager.com/gtag/js?id=${MEASUREMENT_ID}`;
    
    // Initialize dataLayer
    window.dataLayer = window.dataLayer || [];
    function gtag(...args: any[]) {
      window.dataLayer.push(arguments);
    }
    window.gtag = gtag;
    
    // Configure GA
    gtag('js', new Date());
    gtag('config', MEASUREMENT_ID, {
      send_page_view: true,
      page_path: location.pathname + location.search,
      cookie_flags: 'max-age=7200;secure;samesite=none'
    });

    // Add script to head
    document.head.appendChild(script);

    // Cleanup
    return () => {
      const existingScript = document.querySelector(`script[src*="${MEASUREMENT_ID}"]`);
      if (existingScript && existingScript.parentNode) {
        existingScript.parentNode.removeChild(existingScript);
      }
    };
  }, []);

  // Track page views
  useEffect(() => {
    if (window.gtag && MEASUREMENT_ID) {
      window.gtag('event', 'page_view', {
        page_location: window.location.href,
        page_path: location.pathname + location.search,
        page_title: document.title,
        send_to: MEASUREMENT_ID
      });
    }
  }, [location]);

  return null;
};

// Custom hook for tracking events
export const useGoogleAnalytics = () => {
  const trackEvent = (
    eventName: string,
    eventParams?: { [key: string]: any }
  ) => {
    if (window.gtag && MEASUREMENT_ID) {
      window.gtag('event', eventName, {
        ...eventParams,
        send_to: MEASUREMENT_ID
      });
    }
  };

  return { trackEvent };
};
