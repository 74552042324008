import {
  Box,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack,
  Container,
  Portal,
  Avatar,
  VStack,
  Text,
  Divider,
  HStack,
  Link,
  IconButton,
  useBreakpointValue,
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton
} from '@chakra-ui/react';
import { 
  HamburgerIcon, 
  CloseIcon, 
  ChevronDownIcon,
  EmailIcon,
  PhoneIcon,
  InfoIcon 
} from '@chakra-ui/icons';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Logo from '../common/Logo';
import TrackingMenu from '../navigation/TrackingMenu';
import ToolsMenu from '../navigation/ToolsMenu';
import { useAuth } from '../../contexts/AuthContext';

const MotionBox = motion(Box);
const MotionFlex = motion(Flex);

const NavLink = ({ to, children, hasSubmenu = false, onClick, ...rest }: { 
  to: string; 
  children: React.ReactNode;
  hasSubmenu?: boolean;
  onClick?: () => void;
  [key: string]: any;
}) => {
  const location = useLocation();
  const isActive = location.pathname === to;

  return (
    <Box
      as={RouterLink}
      to={to}
      position="relative"
      px={4}
      py={2}
      fontSize="md"
      fontWeight="600"
      color="whiteAlpha.900"
      _hover={{
        color: 'accent.400',
        _after: {
          width: '100%',
        },
      }}
      display="flex"
      alignItems="center"
      onClick={onClick}
      {...rest}
      _after={{
        content: '""',
        position: 'absolute',
        width: isActive ? '100%' : '0%',
        height: '2px',
        bottom: 0,
        left: 0,
        bg: 'accent.400',
        transition: 'all 0.3s ease',
      }}
    >
      {children}
      {hasSubmenu && <ChevronDownIcon ml={1} />}
    </Box>
  );
};

const Navbar = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { isAuthenticated, user, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/');
  };

  return (
    <MotionFlex
      as="nav"
      position="fixed"
      top={0}
      left={0}
      right={0}
      height={{ base: "70px", md: "80px" }}  
      zIndex={1000}
      initial={{ y: -100 }}
      animate={{ y: 0 }}
      transition={{ duration: 0.4 }}
      className="glass-effect"
      pointerEvents="auto"
      willChange="transform"
      style={{
        transform: 'translate3d(0, 0, 0)',
        backfaceVisibility: 'hidden',
      }}
      backdropFilter="blur(8px)"
      bg="rgba(0, 18, 51, 0.85)"
      boxShadow="0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)"
    >
      <Flex
        w="100%"
        h="100%"  
        px={{ base: 4, md: 8 }}
        align="center"
        justify="space-between"
        maxW="8xl"
        mx="auto"
      >
        <Logo size="md" variant="primary" isFooter={false} />

        {/* Desktop Navigation */}
        <Stack
          direction="row"
          spacing={8}
          align="center"
          display={{ base: 'none', md: 'flex' }}
        >
          <NavLink to="/">Home</NavLink>
          <TrackingMenu />
          <ToolsMenu />
          <NavLink to="/blog">Blog</NavLink>
          <NavLink to="/contact">Contact</NavLink>
          
          {/* Auth Menu */}
          {isAuthenticated ? (
            <Menu>
              <MenuButton
                as={Button}
                variant="ghost"
                rightIcon={<ChevronDownIcon />}
                _hover={{ bg: 'whiteAlpha.200' }}
                _active={{ bg: 'whiteAlpha.300' }}
              >
                <HStack spacing={3}>
                  <Avatar
                    size="sm"
                    name={user?.full_name}
                    bg="accent.400"
                    color="white"
                  />
                </HStack>
              </MenuButton>
              <MenuList
                bg="background.primary"
                borderColor="whiteAlpha.200"
                boxShadow="lg"
                p={4}
                minW="300px"
              >
                <VStack align="stretch" spacing={4}>
                  <Box>
                    <Text fontSize="lg" fontWeight="bold" color="whiteAlpha.900">
                      {user?.full_name}
                    </Text>
                    <HStack color="whiteAlpha.600" fontSize="sm">
                      <EmailIcon />
                      <Text>{user?.email}</Text>
                    </HStack>
                    {user?.phone && (
                      <HStack color="whiteAlpha.600" fontSize="sm" mt={1}>
                        <PhoneIcon />
                        <Text>{user?.phone}</Text>
                      </HStack>
                    )}
                    {user?.company && (
                      <HStack color="whiteAlpha.600" fontSize="sm" mt={1}>
                        <InfoIcon />
                        <Text>{user?.company}</Text>
                      </HStack>
                    )}
                  </Box>
                  <Divider borderColor="whiteAlpha.200" />
                  <MenuItem
                    as={RouterLink}
                    to="/dashboard"
                    color="whiteAlpha.900"
                    bg="transparent"
                    _hover={{
                      bg: 'whiteAlpha.200',
                      color: 'accent.400'
                    }}
                  >
                    Dashboard
                  </MenuItem>
                  {user?.is_admin && (
                    <MenuItem
                      as={RouterLink}
                      to="/admin/dashboard"
                      color="whiteAlpha.900"
                      bg="transparent"
                      _hover={{
                        bg: 'whiteAlpha.200',
                        color: 'accent.400'
                      }}
                    >
                      Admin Dashboard
                    </MenuItem>
                  )}
                  <Divider borderColor="whiteAlpha.200" />
                  <MenuItem
                    onClick={() => navigate('/profile')}
                    color="whiteAlpha.900"
                    bg="transparent"
                    _hover={{
                      bg: 'whiteAlpha.200',
                      color: 'accent.400'
                    }}
                  >
                    Edit Profile
                  </MenuItem>
                  <MenuItem
                    onClick={handleLogout}
                    color="red.400"
                    bg="transparent"
                    _hover={{
                      bg: 'whiteAlpha.200'
                    }}
                  >
                    Logout
                  </MenuItem>
                </VStack>
              </MenuList>
            </Menu>
          ) : (
            <Button
              as={RouterLink}
              to="/login"
              variant="ghost"
              size="md"
              fontWeight="600"
              color="whiteAlpha.900"
              _hover={{
                bg: 'whiteAlpha.200'
              }}
            >
              Sign In
            </Button>
          )}

          {/* Get Quote Button - Conditional routing based on auth state */}
          <Button
            as={RouterLink}
            to={isAuthenticated ? "/quote" : "/"}
            variant="accent"
            size="md"
            px={6}
            fontWeight="600"
          >
            Get Quote
          </Button>
        </Stack>

        {/* Mobile Menu Button */}
        <IconButton
          display={{ base: 'flex', md: 'none' }}
          onClick={onOpen}
          variant="ghost"
          color="white"
          aria-label="Open Menu"
          icon={<HamburgerIcon boxSize={6} />}
          _hover={{
            bg: 'whiteAlpha.200'
          }}
        />
      </Flex>

      {/* Mobile Menu */}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent bg="background.primary">
          <DrawerCloseButton color="white" />
          <DrawerHeader borderBottomWidth="1px" borderColor="whiteAlpha.200">
            <Logo size="md" variant="primary" isFooter={false} />
          </DrawerHeader>
          <DrawerBody>
            <VStack spacing={4} align="stretch" mt={4}>
              <NavLink to="/" onClick={onClose}>Home</NavLink>
              <NavLink to="/tracking" onClick={onClose}>Tracking</NavLink>
              <NavLink to="/tools" onClick={onClose}>Tools</NavLink>
              <NavLink to="/blog" onClick={onClose}>Blog</NavLink>
              <NavLink to="/contact" onClick={onClose}>Contact</NavLink>
              
              {isAuthenticated ? (
                <>
                  <Divider borderColor="whiteAlpha.200" />
                  <Box py={2}>
                    <Text color="whiteAlpha.900" fontWeight="medium">
                      {user?.full_name}
                    </Text>
                    <Text color="whiteAlpha.600" fontSize="sm">
                      {user?.email}
                    </Text>
                  </Box>
                  <NavLink to="/dashboard" onClick={onClose}>Dashboard</NavLink>
                  {user?.is_admin && (
                    <NavLink to="/admin/dashboard" onClick={onClose}>Admin Dashboard</NavLink>
                  )}
                  <Button
                    onClick={() => {
                      handleLogout();
                      onClose();
                    }}
                    variant="ghost"
                    color="red.400"
                    justifyContent="flex-start"
                    pl={4}
                    _hover={{
                      bg: 'whiteAlpha.200'
                    }}
                  >
                    Sign Out
                  </Button>
                </>
              ) : (
                <Button
                  as={RouterLink}
                  to="/login"
                  variant="ghost"
                  color="whiteAlpha.900"
                  justifyContent="flex-start"
                  pl={4}
                  onClick={onClose}
                  _hover={{
                    bg: 'whiteAlpha.200'
                  }}
                >
                  Sign In
                </Button>
              )}

              {/* Mobile Menu Get Quote Button - Same conditional logic */}
              <Button
                as={RouterLink}
                to={isAuthenticated ? "/quote" : "/"}
                variant="accent"
                size="md"
                onClick={onClose}
              >
                Get Quote
              </Button>
            </VStack>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </MotionFlex>
  );
};

export default Navbar;
