import React from 'react';
import { Box } from '@chakra-ui/react';
import Navbar from './Navbar';
import Footer from './Footer';
import BackToTop from './BackToTop';

interface LayoutProps {
  children: React.ReactNode;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <Navbar />
      <Box 
        as="main" 
        minH="100vh"
        pt="80px"
      >
        {children}
      </Box>
      <Footer />
      <BackToTop />
    </>
  );
};

export default Layout;
