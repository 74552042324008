import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

declare global {
  interface Window {
    clarity?: (command: string, ...args: any[]) => void;
  }
}

interface ClarityAnalyticsProps {
  projectId: string;
}

const ClarityAnalytics: React.FC<ClarityAnalyticsProps> = ({ projectId }) => {
  const location = useLocation();
  const isDomainAllowed = window.location.hostname === 'frieght.com' || 
                         window.location.hostname === 'www.frieght.com' ||
                         window.location.hostname === 'localhost';

  useEffect(() => {
    if (!isDomainAllowed || !projectId) {
      return;
    }

    try {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.async = true;
      script.src = `https://www.clarity.ms/tag/${projectId}`;
      
      script.onerror = () => {
        console.warn('Failed to load Clarity Analytics');
      };

      document.head.appendChild(script);
    } catch (error) {
      console.warn('Error initializing Clarity:', error);
    }
  }, [projectId, isDomainAllowed]);

  // Track page views only on allowed domain
  useEffect(() => {
    if (window.clarity && isDomainAllowed) {
      try {
        window.clarity("newPage");
      } catch (error) {
        console.warn('Error tracking page view:', error);
      }
    }
  }, [location, isDomainAllowed]);

  return null;
};

export default ClarityAnalytics;
