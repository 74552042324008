import React from 'react';
import { Box } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const MotionBox = motion(Box);

interface LogoProps {
  showTagline?: boolean;
  size?: 'sm' | 'md' | 'lg';
  variant?: 'primary' | 'subdued';
  isFooter?: boolean;
}

const Logo: React.FC<LogoProps> = ({ 
  showTagline = true, 
  size = 'md',
  variant = 'primary',
  isFooter = false
}) => {
  const sizes = {
    sm: {
      brandText: 'lg',
      taglineText: 'xs',
      separatorHeight: '1px',
      my: '2px',
      taglineSpacing: '1',
    },
    md: {
      brandText: 'xl',
      taglineText: 'sm',
      separatorHeight: '1px',
      my: '3px',
      taglineSpacing: '2',
    },
    lg: {
      brandText: '2xl',
      taglineText: 'md',
      separatorHeight: '2px',
      my: '4px',
      taglineSpacing: '3',
    },
  };

  const variants = {
    primary: {
      mainColor: 'white',
      highlightColor: 'accent.400',
      domainColor: 'whiteAlpha.800',
      taglineColor: 'whiteAlpha.800',
      separatorGradient: 'linear(to-r, transparent, accent.400 30%, accent.400 70%, transparent)',
      dotColor: 'accent.400',
    },
    subdued: {
      mainColor: 'gray.300',
      highlightColor: 'gray.400',
      domainColor: 'gray.400',
      taglineColor: 'gray.400',
      separatorGradient: 'linear(to-r, transparent, gray.500 30%, gray.500 70%, transparent)',
      dotColor: 'gray.400',
    },
  };

  const colors = variants[variant];

  return (
    <Link to="/">
      <Box 
        className="brand-container" 
        role="group"
        color={colors.mainColor}
        transition="all 0.3s ease"
        _hover={{
          color: variant === 'subdued' ? 'gray.200' : 'white',
        }}
      >
        <Box 
          className="brand-text"
          fontSize={sizes[size].brandText}
          fontWeight="bold"
          letterSpacing="wider"
        >
          FR<Box as="span" color={colors.highlightColor}>IE</Box>GHT
          <Box 
            as="span" 
            className="domain"
            fontSize={size === 'sm' ? 'md' : sizes[size].brandText}
            ml={1}
            color={colors.domainColor}
          >
            .COM
          </Box>
        </Box>
        
        <MotionBox
          className="brand-separator"
          initial={isFooter ? {} : { scaleX: 0, opacity: 0 }}
          animate={isFooter ? {} : { scaleX: 1, opacity: 1 }}
          transition={{ 
            duration: 0.8, 
            delay: 0.3,
            ease: "easeOut"
          }}
          height={sizes[size].separatorHeight}
          bgGradient={colors.separatorGradient}
          my={sizes[size].my}
          transformOrigin="center"
          opacity={variant === 'subdued' ? 0.7 : 1}
        />

        {showTagline && (
          <Box 
            className="brand-tagline"
            fontSize={sizes[size].taglineText}
            color={colors.taglineColor}
            letterSpacing={sizes[size].taglineSpacing}
            mt={1}
          >
            Innovation <Box as="span" className="tagline-dot" color={colors.dotColor}>•</Box> Excellence
          </Box>
        )}
      </Box>
    </Link>
  );
};

export default Logo;
