import React from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Portal,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Stack,
  Icon,
  useBreakpointValue,
} from '@chakra-ui/react';
import { ChevronDownIcon } from '@chakra-ui/icons';
import { FiExternalLink } from 'react-icons/fi';

const trackingLinks = [
  {
    name: 'DHL Tracking',
    url: 'https://www.dhl.com/en/express/tracking.html'
  },
  {
    name: 'UPS Tracking',
    url: 'https://www.ups.com/track?loc=en_US&requester=ST/'
  },
  {
    name: 'TNT Tracking',
    url: 'https://www.tnt.com/express/en_us/site/tracking.html#'
  },
  {
    name: 'FedEx Tracking',
    url: 'https://www.fedex.com/apps/fedextrack/?action=track'
  },
  {
    name: 'Air Cargo Tracking',
    url: 'https://www.track-trace.com/aircargo'
  },
  {
    name: 'Post Office Tracking',
    url: 'https://www.track-trace.com/post'
  },
  {
    name: 'Container Tracking',
    url: 'https://www.track-trace.com/container'
  },
  {
    name: 'Bill of Lading Tracking',
    url: 'https://www.track-trace.com/bol'
  }
];

interface TrackingMenuProps {
  isMobile?: boolean;
  onItemClick?: () => void;
}

const TrackingMenu: React.FC<TrackingMenuProps> = ({ isMobile = false, onItemClick }) => {
  const fontSize = useBreakpointValue({ base: "sm", md: "md" });
  
  if (isMobile) {
    return (
      <Accordion allowToggle width="100%">
        <AccordionItem border="none">
          <AccordionButton
            px={0}
            py={2}
            _hover={{ bg: 'transparent', color: 'accent.400' }}
            _expanded={{ color: 'accent.400' }}
          >
            <Box
              flex="1"
              textAlign="left"
              fontSize={fontSize}
              fontWeight="600"
              color="whiteAlpha.900"
            >
              Tracking
            </Box>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel pb={4} px={0}>
            <Stack spacing={2}>
              {trackingLinks.map((link) => (
                <Link
                  key={link.name}
                  href={link.url}
                  target="_blank"
                  rel="noopener noreferrer"
                  display="flex"
                  alignItems="center"
                  color="whiteAlpha.900"
                  fontSize={fontSize}
                  fontWeight="500"
                  py={2}
                  _hover={{
                    color: 'accent.400',
                    textDecoration: 'none'
                  }}
                  onClick={onItemClick}
                >
                  {link.name}
                  <Icon as={FiExternalLink} ml={2} fontSize="sm" />
                </Link>
              ))}
            </Stack>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }

  return (
    <Menu>
      <MenuButton
        as={Box}
        position="relative"
        px={4}
        py={2}
        fontSize={fontSize}
        fontWeight="600"
        color="whiteAlpha.900"
        _hover={{ color: 'accent.400' }}
        display="flex"
        alignItems="center"
        cursor="pointer"
      >
        Tracking <ChevronDownIcon ml={1} />
      </MenuButton>
      <Portal>
        <MenuList
          bg="background.primary"
          borderColor="whiteAlpha.200"
          boxShadow="lg"
          py={2}
          mt={2}
        >
          {trackingLinks.map((link) => (
            <MenuItem
              key={link.name}
              as={Link}
              href={link.url}
              target="_blank"
              rel="noopener noreferrer"
              color="whiteAlpha.900"
              bg="transparent"
              _hover={{
                bg: 'whiteAlpha.200',
                color: 'accent.400',
                textDecoration: 'none'
              }}
              fontSize={fontSize}
              fontWeight="500"
              icon={<FiExternalLink />}
              onClick={onItemClick}
            >
              {link.name}
            </MenuItem>
          ))}
        </MenuList>
      </Portal>
    </Menu>
  );
};

export default TrackingMenu;
