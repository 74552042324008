import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import api from '../api/axios';
import * as authService from '../api/authService';
import { User } from '../types/auth';

interface AuthContextType {
  isAuthenticated: boolean;
  user: User | null;
  setUser: (user: User | null) => void;
  login: (email: string, password: string, turnstileToken: string) => Promise<void>;
  logout: () => void;
  resetPassword: (email: string, turnstileToken: string) => Promise<void>;
  resendVerification: (email: string) => Promise<void>;
  isLoading: boolean;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  // Function to fetch user data
  const fetchUserData = useCallback(async (token: string) => {
    try {
      console.log('[AuthContext] Fetching user data with token');
      const userResponse = await api.get('/api/auth/me', {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      });
      console.log('[AuthContext] User data received:', userResponse.data);
      setUser(userResponse.data);
      setIsAuthenticated(true);
    } catch (error) {
      console.error('[AuthContext] Error fetching user data:', error);
      // If there's an error, clear the invalid token
      localStorage.removeItem('token');
      setIsAuthenticated(false);
      setUser(null);
    }
  }, []);

  // Check for existing token on mount
  useEffect(() => {
    const initializeAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        console.log('[AuthContext] Found existing token, initializing auth');
        try {
          await fetchUserData(token);
        } catch (error) {
          console.error('[AuthContext] Error during auth initialization:', error);
          localStorage.removeItem('token');
          setIsAuthenticated(false);
          setUser(null);
        }
      } else {
        console.log('[AuthContext] No token found');
      }
      setIsLoading(false);
    };

    initializeAuth();
  }, [fetchUserData]);

  const login = useCallback(async (email: string, password: string, turnstileToken: string) => {
    try {
      console.log('[AuthContext] Attempting login with:', { email });
      
      const response = await authService.login({ email, password, turnstileToken });
      console.log('[AuthContext] Login response:', response);

      if (response.access_token) {
        localStorage.setItem('token', response.access_token);
        await fetchUserData(response.access_token);
      }
    } catch (error) {
      console.error('[AuthContext] Login error:', error);
      throw error;
    }
  }, [fetchUserData]);

  const logout = useCallback(() => {
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    setUser(null);
    window.location.href = '/login';
  }, []);

  const resetPassword = useCallback(async (email: string, turnstileToken: string) => {
    try {
      await authService.requestPasswordReset({ email, turnstileToken });
    } catch (error) {
      console.error('[AuthContext] Reset password error:', error);
      throw error;
    }
  }, []);

  const resendVerification = useCallback(async (email: string) => {
    try {
      await api.post('/api/auth/resend-verification', { email });
    } catch (error) {
      console.error('[AuthContext] Resend verification error:', error);
      throw error;
    }
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        setUser,
        login,
        logout,
        resetPassword,
        resendVerification,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export default AuthContext;
