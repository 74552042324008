import React, { lazy, Suspense } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { BrowserRouter as Router, Routes, Route, Outlet } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import theme from './styles/theme';
import { AuthProvider } from './contexts/AuthContext';
import Layout from './components/layout/Layout';
import PrivateRoute from './components/auth/PrivateRoute';
import AdminRoute from './components/auth/AdminRoute';
import Loading from './components/common/Loading';
import { GoogleAnalytics } from './components/GoogleAnalytics';
import ClarityAnalytics from './components/analytics/ClarityAnalytics';

// Lazy loaded components
const Home = lazy(() => import('./pages/Home'));
const Login = lazy(() => import('./pages/Login'));
const Register = lazy(() => import('./pages/Register'));
const Tracking = lazy(() => import('./pages/Tracking'));
const Blog = lazy(() => import('./pages/Blog'));
const Contact = lazy(() => import('./pages/Contact'));
const Quote = lazy(() => import('./pages/Quote'));
const QuoteSuccess = lazy(() => import('./pages/QuoteSuccess'));
const Dashboard = lazy(() => import('./pages/Dashboard'));
const AdminDashboard = lazy(() => import('./pages/AdminDashboard'));
const Profile = lazy(() => import('./pages/Profile'));
const FreightClassCalculatorPage = lazy(() => import('./pages/FreightClassCalculatorPage'));
const ZoneLookupPage = lazy(() => import('./pages/ZoneLookupPage'));
const FuelPricesPage = lazy(() => import('./pages/FuelPricesPage'));
const Tools = lazy(() => import('./pages/Tools'));
const GlossaryPage = lazy(() => import('./pages/GlossaryPage'));
const FAQ = lazy(() => import('./pages/FAQ'));
const PrivacyPolicy = lazy(() => import('./pages/PrivacyPolicy'));
const TermsOfService = lazy(() => import('./pages/TermsOfService'));
const CookiePolicy = lazy(() => import('./pages/CookiePolicy'));
const Sitemap = lazy(() => import('./pages/Sitemap'));
const TrackShipment = lazy(() => import('./pages/TrackShipment'));
const VerifyEmail = lazy(() => import('./pages/VerifyEmail'));
const VerifyEmailPending = lazy(() => import('./pages/VerifyEmailPending'));
const ForgotPassword = lazy(() => import('./pages/ForgotPassword'));
const ResetPassword = lazy(() => import('./pages/ResetPassword'));

function App() {
  return (
    <ChakraProvider theme={theme}>
      <HelmetProvider>
        <Router>
          {/* Add Clarity Analytics */}
          <ClarityAnalytics projectId={import.meta.env.VITE_CLARITY_PROJECT_ID} />
          <AuthProvider>
            <Suspense fallback={<Loading />}>
              <Routes>
                <Route path="/" element={<Layout><Outlet /></Layout>}>
                  <Route index element={<Home />} />
                  <Route path="login" element={<Login />} />
                  <Route path="register" element={<Register />} />
                  <Route path="tracking" element={<Tracking />} />
                  <Route path="blog" element={<Blog />} />
                  <Route path="contact" element={<Contact />} />
                  <Route path="quote" element={<Quote />} />
                  <Route path="quote/success" element={<QuoteSuccess />} />
                  <Route path="tools" element={<Tools />} />
                  <Route path="tools/freight-class-calculator" element={<FreightClassCalculatorPage />} />
                  <Route path="tools/zone-lookup" element={<ZoneLookupPage />} />
                  <Route path="tools/fuel-prices" element={<FuelPricesPage />} />
                  <Route path="tools/glossary" element={<GlossaryPage />} />
                  <Route path="faq" element={<FAQ />} />
                  <Route path="privacy-policy" element={<PrivacyPolicy />} />
                  <Route path="terms-of-service" element={<TermsOfService />} />
                  <Route path="cookie-policy" element={<CookiePolicy />} />
                  <Route path="sitemap" element={<Sitemap />} />
                  <Route path="track/:trackingNumber" element={<TrackShipment />} />
                  <Route path="verify-email/:token" element={<VerifyEmail />} />
                  <Route path="verify-email-pending" element={<VerifyEmailPending />} />
                  <Route path="forgot-password" element={<ForgotPassword />} />
                  <Route path="reset-password" element={<ResetPassword />} />

                  {/* Protected Routes */}
                  <Route element={<PrivateRoute><Outlet /></PrivateRoute>}>
                    <Route path="dashboard" element={<Dashboard />} />
                    <Route path="profile" element={<Profile />} />
                  </Route>

                  {/* Admin Routes */}
                  <Route element={<AdminRoute><Outlet /></AdminRoute>}>
                    <Route path="admin/dashboard" element={<AdminDashboard />} />
                  </Route>
                </Route>
              </Routes>
            </Suspense>
          </AuthProvider>
          {/* Add Google Analytics */}
          <GoogleAnalytics />
        </Router>
      </HelmetProvider>
    </ChakraProvider>
  );
}

export default App;
