import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { 
  Box, 
  Container, 
  SimpleGrid, 
  Text, 
  VStack, 
  HStack, 
  Divider, 
  Link,
  useBreakpointValue,
  Stack,
  Heading,
} from '@chakra-ui/react';
import { FiMail, FiMapPin } from 'react-icons/fi';
import Logo from '../common/Logo';

const Footer: React.FC = () => {
  const spacing = useBreakpointValue({ base: 6, md: 8 });
  const fontSize = useBreakpointValue({ base: 'sm', md: 'md' });

  return (
    <Box 
      as="footer" 
      bg="navy.900" 
      color="white" 
      py={{ base: 6, md: 8 }}
      borderTop="1px solid"
      borderColor="whiteAlpha.100"
    >
      <Container maxW="8xl" px={{ base: 4, md: 6 }}>
        <SimpleGrid 
          columns={{ base: 1, sm: 2, md: 3 }} 
          spacing={spacing}
          pb={spacing}
        >
          {/* Company Info */}
          <VStack align="start" spacing={3}>
            <Logo size="sm" variant="subdued" isFooter={true} showTagline={false} />
            <Text color="whiteAlpha.700" fontSize="sm" maxW="xs">
              Innovating logistics with a unique perspective—just like our spelling.
            </Text>
          </VStack>

          {/* Quick Links */}
          <Stack align={'flex-start'}>
            <Text fontSize={fontSize} fontWeight="semibold" color="whiteAlpha.900">Quick Links</Text>
            <Link as={RouterLink} to="/track" color="whiteAlpha.700" fontSize="sm" _hover={{ color: 'accent.400' }}>Track Shipment</Link>
            <Link as={RouterLink} to="/contact" color="whiteAlpha.700" fontSize="sm" _hover={{ color: 'accent.400' }}>Contact Us</Link>
            <Link as={RouterLink} to="/privacy-policy" color="whiteAlpha.700" fontSize="sm" _hover={{ color: 'accent.400' }}>Privacy Policy</Link>
            <Link as={RouterLink} to="/terms-of-service" color="whiteAlpha.700" fontSize="sm" _hover={{ color: 'accent.400' }}>Terms of Service</Link>
          </Stack>

          {/* Resources */}
          <VStack align="start" spacing={3}>
            <Text fontSize={fontSize} fontWeight="semibold" color="whiteAlpha.900">Resources</Text>
            <VStack align="start" spacing={2}>
              <Link as={RouterLink} to="/faq" color="whiteAlpha.700" fontSize="sm" _hover={{ color: 'accent.400' }}>FAQ</Link>
              <Link as={RouterLink} to="/blog" color="whiteAlpha.700" fontSize="sm" _hover={{ color: 'accent.400' }}>Blog</Link>
              <Link as={RouterLink} to="/tools/glossary" color="whiteAlpha.700" fontSize="sm" _hover={{ color: 'accent.400' }}>Freight Glossary</Link>
              <Link as={RouterLink} to="/tools/freight-class-calculator" color="whiteAlpha.700" fontSize="sm" _hover={{ color: 'accent.400' }}>Freight Class Calculator</Link>
              <Link as={RouterLink} to="/sitemap" color="whiteAlpha.700" fontSize="sm" _hover={{ color: 'accent.400' }}>Sitemap</Link>
            </VStack>
          </VStack>
        </SimpleGrid>

        <Divider borderColor="whiteAlpha.200" />

        <Box 
          pt={4}
          display="flex" 
          flexDirection={{ base: 'column', sm: 'row' }} 
          justifyContent="space-between" 
          alignItems={{ base: 'center', sm: 'center' }}
          textAlign={{ base: 'center', sm: 'left' }}
          gap={{ base: 3, sm: 0 }}
        >
          <Text color="whiteAlpha.600" fontSize="xs">
            &copy; {new Date().getFullYear()} Frieght.com. All rights reserved.
          </Text>
          <HStack 
            spacing={{ base: 2, sm: 4 }} 
            color="whiteAlpha.600" 
            fontSize="xs"
            flexWrap="wrap"
            justify={{ base: 'center', sm: 'flex-end' }}
          >
            <Link as={RouterLink} to="/privacy-policy" _hover={{ color: 'accent.400' }}>Privacy Policy</Link>
            <Box as="span" display={{ base: 'none', sm: 'inline' }}>•</Box>
            <Link as={RouterLink} to="/terms-of-service" _hover={{ color: 'accent.400' }}>Terms of Service</Link>
            <Box as="span" display={{ base: 'none', sm: 'inline' }}>•</Box>
            <Link as={RouterLink} to="/cookie-policy" _hover={{ color: 'accent.400' }}>Cookie Policy</Link>
          </HStack>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
