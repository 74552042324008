import { extendTheme } from '@chakra-ui/react';

const colors = {
  primary: {
    50: '#E5EAF3',
    100: '#BCC8E1',
    200: '#93A6CF',
    300: '#6A84BD',
    400: '#4162AB',
    500: '#00205B', // Main primary color
    600: '#001C52',
    700: '#001849',
    800: '#001440',
    900: '#000C37',
  },
  accent: {
    50: '#FFF2EC',
    100: '#FFD9C9',
    200: '#FFC0A6',
    300: '#FFA783',
    400: '#FF8E60',
    500: '#FF4500', // Main accent color
    600: '#E63E00',
    700: '#CC3700',
    800: '#B33000',
    900: '#992900',
  },
  background: {
    primary: '#001233',    // Main background color for everything
    secondary: '#001438',  // Very slightly lighter for subtle depth
    tertiary: '#00163D',   // Just a touch lighter for hover states
    card: '#001233',       // Same as primary for consistency
    hover: '#001438',      // Same as secondary for subtle hover effect
  }
};

const fonts = {
  body: 'Inter, system-ui, -apple-system, sans-serif',
  heading: 'Inter, system-ui, -apple-system, sans-serif',
  mono: 'Menlo, monospace',
};

const styles = {
  global: {
    'html, body': {
      bg: 'background.primary',
      color: 'whiteAlpha.900',
      fontFamily: 'body',
      height: '100%',
      margin: 0,
      padding: 0,
      overscrollBehavior: 'auto',
      WebkitOverflowScrolling: 'touch',
    },
    body: {
      minHeight: '100vh',
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    '#root': {
      height: '100%',
      isolation: 'isolate',
    },
    '.glass-effect': {
      backgroundColor: 'rgba(0, 18, 51, 0.95)',
      backdropFilter: 'blur(8px)',
      borderColor: 'whiteAlpha.100',
    },
    '.section-gradient': {
      background: 'linear-gradient(180deg, background.primary 0%, background.secondary 100%)',
      borderTop: '1px solid',
      borderColor: 'whiteAlpha.100',
    },
    '.brand-container': {
      fontFamily: 'heading',
      letterSpacing: '0.02em',
      lineHeight: '1.2',
      transition: 'all 0.3s ease',
      display: 'inline-block',
      _groupHover: {
        transform: 'scale(1.02)',
        '.brand-separator': {
          opacity: 0.9,
          transform: 'scaleX(1.1)',
        },
        '.brand-tagline': {
          color: 'whiteAlpha.900'
        },
        '.tagline-dot': {
          color: 'accent.400'
        }
      }
    },
    '.brand-text': {
      fontSize: '1.8rem',
      fontWeight: '700',
      color: 'whiteAlpha.900',
      letterSpacing: '0.05em',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    '.brand-text .highlight': {
      color: 'accent.500',
      fontWeight: '800',
    },
    '.brand-text .domain': {
      fontSize: '0.7em',
      opacity: '0.8',
      fontWeight: '600',
      letterSpacing: '0.02em',
    },
    '.brand-separator': {
      opacity: 0.7,
      width: '100%',
      maxWidth: '140px',
      mx: 'auto',
      transition: 'all 0.3s ease',
    },
    '.brand-tagline': {
      fontSize: '0.85rem',
      color: 'whiteAlpha.700',
      fontWeight: '500',
      letterSpacing: '0.1em',
      marginTop: '2px',
      textAlign: 'center',
      transition: 'color 0.3s ease',
      whiteSpace: 'nowrap',
    },
    '.tagline-dot': {
      color: 'accent.500',
      mx: '4px',
      transition: 'color 0.3s ease',
    }
  },
};

const components = {
  Button: {
    variants: {
      solid: {
        bg: 'background.tertiary',
        color: 'whiteAlpha.900',
        _hover: {
          bg: 'background.hover',
          transform: 'translateY(-2px)',
          boxShadow: 'lg',
        },
      },
      outline: {
        borderColor: 'whiteAlpha.400',
        color: 'whiteAlpha.900',
        _hover: {
          bg: 'whiteAlpha.100',
          transform: 'translateY(-2px)',
        },
      },
      accent: {
        bg: 'accent.500',
        color: 'white',
        _hover: {
          bg: 'accent.600',
          transform: 'translateY(-2px)',
          boxShadow: 'lg',
        },
      },
      ghost: {
        color: 'whiteAlpha.900',
        _hover: {
          bg: 'whiteAlpha.100',
          color: 'accent.400',
        },
      },
    },
  },
  Text: {
    baseStyle: {
      color: 'whiteAlpha.800',
    },
    variants: {
      secondary: {
        color: 'whiteAlpha.700',
      },
      accent: {
        color: 'accent.400',
      },
    },
  },
  Heading: {
    baseStyle: {
      color: 'whiteAlpha.900',
    },
  },
  Card: {
    baseStyle: {
      container: {
        bg: 'background.card',
        borderRadius: 'xl',
      },
    },
  },
  Link: {
    baseStyle: {
      color: 'accent.400',
      _hover: {
        textDecoration: 'none',
        color: 'accent.500',
      },
    },
  },
};

const theme = extendTheme({
  colors,
  fonts,
  components,
  styles,
});

export default theme;
