import React, { useEffect, useState } from 'react';
import { IconButton } from '@chakra-ui/react';
import { FiArrowUp } from 'react-icons/fi';

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const toggleVisibility = () => {
      if (window.scrollY > 200) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);
    toggleVisibility(); // Check initial scroll position

    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  if (!isVisible) {
    return null;
  }

  return (
    <IconButton
      aria-label="Back to top"
      icon={<FiArrowUp size={20} />}
      onClick={scrollToTop}
      position="fixed"
      bottom="20px"
      right="20px"
      size="lg"
      isRound
      bg="accent.500"
      color="white"
      _hover={{
        bg: 'accent.600',
        transform: 'translateY(-2px)',
      }}
      _active={{
        bg: 'accent.700',
        transform: 'translateY(0)',
      }}
      boxShadow="0 4px 12px rgba(0,0,0,0.3)"
      zIndex={999999}
    />
  );
};

export default BackToTop;
