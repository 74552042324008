declare global {
  interface Window {
    RUNTIME_CONFIG: {
      API_URL: string;
    };
  }
}

// Determine environment
const isProduction = import.meta.env.PROD;

// API configuration
const apiUrl = (import.meta.env.VITE_API_URL || 'http://localhost:8000').replace(/\/+$/, '');
console.log('[CONFIG_TS] Raw API URL:', apiUrl);

// Ensure HTTPS is used in production
export const API_BASE_URL = isProduction
  ? apiUrl.replace('http://', 'https://')
  : apiUrl;

// Google Maps Configuration
export const GOOGLE_MAPS_CONFIG = {
    apiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY || '',
    libraries: ['places'] as const,
    region: 'US',
};

// Other configuration variables can be added here
