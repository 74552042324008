import axios from 'axios';
import { API_BASE_URL } from '../config';

console.log('[AXIOS_TS] Received API_BASE_URL:', API_BASE_URL);
console.log('[AXIOS_TS] Environment Variables:', import.meta.env);

// Create axios instance with base URL
const api = axios.create({
    baseURL: API_BASE_URL,
});

// Add request interceptor for debugging only
api.interceptors.request.use(request => {
    // Add auth token if available
    const token = localStorage.getItem('token');
    const refreshToken = localStorage.getItem('refresh_token');
    console.log('[AXIOS_INTERCEPTOR] Auth state:', {
        hasToken: !!token,
        tokenValue: token ? token.substring(0, 20) + '...' : null,
        hasRefreshToken: !!refreshToken,
        refreshTokenValue: refreshToken ? refreshToken.substring(0, 20) + '...' : null,
        url: request.url,
        method: request.method
    });
    
    if (token) {
        request.headers.Authorization = `Bearer ${token}`;
        console.log('[AXIOS_INTERCEPTOR] Added Authorization header:', `Bearer ${token.substring(0, 20)}...`);
    } else {
        console.log('[AXIOS_INTERCEPTOR] No token found in localStorage');
    }

    // Ensure URL is using HTTPS except for localhost
    const fullUrl = new URL(
        request.url || '',
        request.baseURL || API_BASE_URL
    ).toString();
    
    if (fullUrl.startsWith('http://') && !fullUrl.includes('localhost')) {
        request.url = fullUrl.replace('http://', 'https://');
    }
    
    // Debug log for form data
    let debugData = request.data;
    if (request.data instanceof URLSearchParams) {
        debugData = Object.fromEntries(request.data);
    }
    
    console.log('[AXIOS_INTERCEPTOR] Request details:', {
        originalBaseURL: API_BASE_URL,
        configuredBaseURL: request.baseURL,
        fullURL: fullUrl,
        method: request.method,
        headers: request.headers,
        path: request.url,
        baseURL: request.baseURL,
        environment: import.meta.env.MODE,
        data: debugData,
        params: request.params,
        dataType: request.data ? request.data.constructor.name : null
    });

    return request;
}, error => {
    return Promise.reject(error);
});

// Add response interceptor for error handling
api.interceptors.response.use(
    response => response,
    error => {
        console.log('[AXIOS_INTERCEPTOR] Error details:', {
            status: error.response?.status,
            statusText: error.response?.statusText,
            data: error.response?.data,
            config: error.config
        });
        return Promise.reject(error);
    }
);

export default api;
